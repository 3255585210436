import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";

// Images
// import wepay from "assets/images/integration-logos/wepay.png";
import autotask from "assets/images/integration-logos/autotask.png";
import stripe from "assets/images/integration-logos/stripe-logo.svg";
import xero from "assets/images/integration-logos/xero-dashboard.png";
import quickbook from "assets/images/integration-logos/quickbook.png";
import connectwise from "assets/images/integration-logos/connectwise.png";
import authorizeNet from "assets/images/integration-logos/authorize-net.png";

// styles
import pxToRem from "assets/theme/functions/pxToRem";

const Logo = () => (
  <MKBox component="section" id="integrations">
    <Container>
      <Grid
        container
        spacing={3}
        sx={{
          mb: 12,
          p: `${pxToRem(12)} ${pxToRem(24)}`,
          background: "whitesmoke",
          display: "flex !important",
          alignItems: "center !important",
          width: "100%",
          ml: 0,
        }}
      >
        <Grid item xs={6} md={4} lg={2}>
          <MKBox
            component={Link}
            to="/integration/quickbook"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox component="img" src={quickbook} alt="quickbook" width="100%" opacity={0.7} />
          </MKBox>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MKBox
            component={Link}
            to="/integration/autotask"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox component="img" src={autotask} alt="autotask" width="100%" opacity={0.7} />
          </MKBox>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MKBox
            component={Link}
            to="/integration/connectwise"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox component="img" src={connectwise} alt="connectwise" width="100%" opacity={0.7} />
          </MKBox>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MKBox
            component={Link}
            to="/integration/xero"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox component="img" src={xero} alt="xero" width="70%" opacity={0.7} />
          </MKBox>
        </Grid>
        <Grid item xs={6} md={4} lg={2} sx={{ display: "flex", justifyContent: "center" }}>
          <MKBox
            component={Link}
            to="/integration/authorize"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox
              component="img"
              src={authorizeNet}
              alt="authorizeNet"
              width="100%"
              opacity={0.7}
            />
          </MKBox>
        </Grid>
        {/* <Grid item xs={6} md={4} lg={2}>
          <MKBox
            component={Link}
            to="/integration/authorize"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox component="img" src={wepay} alt="wepay" width="70%" opacity={0.7} />
          </MKBox>
        </Grid> */}
        <Grid item xs={6} md={4} lg={2}>
          <MKBox
            component={Link}
            to="/integration/stripe"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox component="img" src={stripe} alt="wepay" width="70%" opacity={0.7} />
          </MKBox>
        </Grid>
        <MKTypography
          variant="body2"
          color="secondary"
          sx={{ mx: "auto", fontSize: "0.7rem", textAlign: "center", mt: 1 }}
        >
          Authorize.net and the Authorize.net logo are trademarks of CyberSource Corporation.
        </MKTypography>
      </Grid>
    </Container>
  </MKBox>
);

export default Logo;
