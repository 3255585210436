// core components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import NavBar from "components/Navbar";
import CenteredFooter from "components/Footer/CenteredFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import arrowIcon from "assets/images/arrow.png";
import checkIcon from "assets/images/check-icon.png";
import brandBlue from "assets/images/logos/logo.png";
import backgroundImg from "assets/images/background-integration.png";
import autotaskLogo from "assets/images/integration-logos/autotask.jpeg";

const features = [
  {
    title: "Customer Data",
    content: "Sync customer data from ConnectWise Manage to PayMSP with complete field mapping",
  },
  {
    title: "Custom Contact",
    content:
      "Import customer contacts into PayMSP and invite finance contacts to the payment portal",
  },
  {
    title: "Invoice Sync",
    content: "Import ConnectWise Manage Invoices into PayMSP",
  },
  {
    title: "Payment Sync",
    content: "Payments made in PayMSP is pushed back to ConnectWise Manage",
  },
];

function Autotask() {
  return (
    <>
      <NavBar routes={routes} sticky />
      <MKBox
        height="fit-content"
        component="img"
        src={backgroundImg}
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Container>
        <Card
          sx={{
            py: 5,
            px: 2,
            mx: { xs: 0, lg: 3 },
            my: 4,
            mt: -8,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container item xs={12} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h2"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                textAlign: "center",
              })}
            >
              Autotask Integration Features
            </MKTypography>
          </Grid>

          <MKBox
            component="section"
            py={1}
            mt={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          >
            <MKBox>
              {features.map(({ content, title }) => (
                <MKBox my={10}>
                  <MKBox display="flex">
                    <MKBox
                      component="img"
                      src={checkIcon}
                      sx={{ mr: 3, width: { md: "3rem", xs: "1.5rem" }, mx: { md: "2rem", xm: 0 } }}
                      height="100%"
                    />
                    <Grid container item xs={12} lg={6} spacing={3}>
                      <MKBox ml={2}>
                        <MKTypography variant="h4" mb={2}>
                          {title}
                        </MKTypography>
                        <MKTypography variant="body2" color="text">
                          {content}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  </MKBox>
                </MKBox>
              ))}
            </MKBox>

            <MKBox>
              <MKBox mb={3} display="flex" sx={{ flexDirection: "column" }} alignItems="center">
                <MKBox
                  component="img"
                  src={brandBlue}
                  alt="quickbook"
                  height="100%"
                  sx={{ width: { md: "14rem", xs: "7rem" } }}
                />
                <MKBox
                  component="img"
                  src={arrowIcon}
                  alt="quickbook"
                  sx={{
                    width: { md: "3rem", xs: "2rem" },
                    my: "2rem",
                    transform: "rotate(90deg)",
                  }}
                  height="100%"
                />
                <MKBox
                  component="img"
                  src={autotaskLogo}
                  alt="quickbook"
                  height="100%"
                  sx={{ width: { md: "17rem", xs: "7rem" } }}
                />
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      </Container>

      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Autotask;
