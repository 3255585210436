// core components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import NavBar from "components/Navbar";
import CenteredFooter from "components/Footer/CenteredFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import arrowIcon from "assets/images/arrow.png";
import checkIcon from "assets/images/check-icon.png";
import brandBlue from "assets/images/logos/logo.png";
import xeroLogo from "assets/images/integration-logos/xero.png";
import backgroundImg from "assets/images/background-integration.png";

const features = [
  {
    title: "Customer Data",
    content: "Sync customer data from Xero to PayMSP with complete field mapping",
  },
  {
    title: "2-way Invoice sync",
    content: "",
  },
  {
    title: "Invoice Sync",
    content: "Import Xero Invoices into PayMSP",
  },
  {
    title: "Payment Sync to Xero",
    content: "Payments are automatically pushed to Xero for easy reconciliation",
  },
];

function Xero() {
  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <NavBar routes={routes} relative transparent />
      </MKBox>
      <MKBox
        height="fit-content"
        component="img"
        src={backgroundImg}
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Container>
        <Card
          sx={{
            py: 5,
            px: 2,
            mx: { xs: 2, lg: 3 },
            my: 4,
            mt: -8,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container item xs={12} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h2"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                textAlign: "center",
              })}
            >
              Xero 2-way Integration Features
            </MKTypography>
          </Grid>
          <MKBox
            component="section"
            py={1}
            mt={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          >
            <MKBox>
              {features.map(({ content, title }) => (
                <MKBox my={10}>
                  <MKBox display="flex">
                    <MKBox
                      component="img"
                      src={checkIcon}
                      sx={{ mr: 3, width: { md: "3rem", xs: "1.5rem" }, mx: { md: "2rem", xm: 0 } }}
                      height="100%"
                    />
                    <Grid container item xs={12} lg={6} spacing={3}>
                      <MKBox ml={2}>
                        <MKTypography variant="h4" mb={2}>
                          {title}
                        </MKTypography>
                        {title === "2-way Invoice sync" ? (
                          <MKTypography variant="body2" color="text">
                            Xero Invoices &gt;&gt;&gt; PayMSP
                            <br />
                            PSA Invoices &gt;&gt;&gt; PayMSP &gt;&gt;&gt; Xero
                          </MKTypography>
                        ) : (
                          <MKTypography variant="body2" color="text">
                            {content}
                          </MKTypography>
                        )}
                      </MKBox>
                    </Grid>
                  </MKBox>
                </MKBox>
              ))}
            </MKBox>
            <MKBox>
              <MKBox mb={3} display="flex" sx={{ flexDirection: "column" }} alignItems="center">
                <MKBox
                  component="img"
                  src={brandBlue}
                  alt="quickbook"
                  height="100%"
                  sx={{ width: { md: "14rem", xs: "7rem" } }}
                />
                <MKBox
                  component="img"
                  src={arrowIcon}
                  alt="quickbook"
                  sx={{
                    width: { md: "3rem", xs: "2rem" },
                    my: "2rem",
                    transform: "rotate(90deg)",
                  }}
                  height="100%"
                />
                <MKBox
                  component="img"
                  src={xeroLogo}
                  alt="quickbook"
                  height="100%"
                  sx={{ width: { md: "8rem", xs: "5rem" } }}
                />
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      </Container>

      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Xero;
