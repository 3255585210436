// core components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import NavBar from "components/Navbar";
import CenteredFooter from "components/Footer/CenteredFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function TermsofUse() {
  return (
    <>
      <NavBar routes={routes} sticky />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    TERMS OF SERVICE AGREEMENT
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="body2" color="text" mt={6} mb={3}>
                    PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR
                    USING OUR SITES AND OUR SERVICES, YOU HEREBY AGREE TO BE BOUND BY THE TERMS AND
                    ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE
                    USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE
                    PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS
                    AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITES OR OUR SERVICES. THIS
                    TERMS OF SERVICE AGREEMENT IS EFFECTIVE AS OF 08/01/2022.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    ACCEPTANCE OF TERMS
                  </MKTypography>
                  <MKTypography variant="body2" mt={6} mb={3} color="text">
                    The following Terms of Service Agreement (the &quot;TOS&quot;) is a legally
                    binding agreement that shall govern the relationship with our users and others
                    which may interact or interface with Bluelink Systems, also known as PayMSP,
                    located at 13809 N Highway 183 Ste 500K, Austin, Texas 78750 and our
                    subsidiaries and affiliates, in association with the use of the PayMSP website,
                    which includes{" "}
                    <MKTypography
                      component="a"
                      href="/"
                      variant="body2"
                      fontWeight="bold"
                      sx={{ textDecoration: "underline" }}
                    >
                      https://www.paymsp.com/
                    </MKTypography>
                    , (the &quot;Site&quot;) and its Services, which shall be defined below.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    DESCRIPTION OF WEBSITE SERVICES OFFERED
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    The Site is payment portal which has the following description:
                    <br />
                    Provides customer portal, invoice sync and payment portal for Managed Services
                    Providers
                    <br />
                    Any and all visitors to our site, despite whether they are registered or not,
                    shall be deemed as &quot;users&quot; of the herein contained Services provided
                    for the purpose of this TOS. Once an individual register&apos;s for our
                    Services, through the process of creating an account, the user shall then be
                    considered a &quot;member.&quot;
                    <br />
                    The user and/or member acknowledges and agrees that the Services provided and
                    made available through our website and applications, which may include some
                    mobile applications and that those applications may be made available on various
                    social media networking sites and numerous other platforms and downloadable
                    programs, are the sole property of Bluelink Systems. At its discretion, Bluelink
                    Systems may offer additional website Services and/or products, or update, modify
                    or revise any current content and Services, and this Agreement shall apply to
                    any and all additional Services and/or products and any and all updated,
                    modified or revised Services unless otherwise stipulated. Bluelink Systems does
                    hereby reserve the right to cancel and cease offering any of the aforementioned
                    Services and/or products. You, as the end user and/or member, acknowledge,
                    accept and agree that Bluelink Systems shall not be held liable for any such
                    updates, modifications, revisions, suspensions or discontinuance of any of our
                    Services and/or products. Your continued use of the Services provided, after
                    such posting of any updates, changes, and/or modifications shall constitute your
                    acceptance of such updates, changes and/or modifications, and as such, frequent
                    review of this Agreement and any and all applicable terms and policies should be
                    made by you to ensure you are aware of all terms and policies currently in
                    effect. Should you not agree to the updated, revised or modified terms, you must
                    stop using the provided Services forthwith.
                    <br />
                    Furthermore, the user and/or member understands, acknowledges and agrees that
                    the Services offered shall be provided &quot;AS IS&quot; and as such Bluelink
                    Systems shall not assume any responsibility or obligation for the timeliness,
                    missed delivery, deletion and/or any failure to store user content,
                    communication or personalization settings.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    REGISTRATION
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    To register and become a &quot;member&quot; of the Site, you must be at least 18
                    years of age to enter into and form a legally binding contract. In addition, you
                    must be in good standing and not an individual that has been previously barred
                    from receiving PayMSP&apos;s Services under the laws and statutes of the United
                    States or other applicable jurisdiction.
                    <br />
                    When you register, PayMSP may collect information such as your name, e-mail
                    address, birth date, gender, mailing address, occupation, industry and personal
                    interests. You can edit your account information at any time. Once you register
                    with PayMSP and sign in to our Services, you are no longer anonymous to us.
                    <br />
                    Furthermore, the registering party hereby acknowledges, understands and agrees
                    to:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        furnish factual, correct, current and complete information with regards to
                        yourself as may be requested by the data registration process, and
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        maintain and promptly update your registration and profile information in an
                        effort to maintain accuracy and completeness at all times.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    If anyone knowingly provides any information of a false, untrue, inaccurate or
                    incomplete nature, Bluelink Systems will have sufficient grounds and rights to
                    suspend or terminate the member in violation of this aspect of the Agreement,
                    and as such refuse any and all current or future use of Bluelink Systems
                    Services, or any portion thereof.
                    <br />
                    It is Bluelink Systems&apos;s priority to ensure the safety and privacy of all
                    its visitors, users and members, especially that of children. Therefore, it is
                    for this reason that the parents of any child under the age of 13 that permit
                    their child or children access to the PayMSP website platform Services must
                    create a &quot;family&quot;; account, which will certify that the individual
                    creating the &quot;family&quot;; account is of 18 years of age and as such, the
                    parent or legal guardian of any child or children registered under the
                    &quot;family&quot;; account. As the creator of the &quot;family&quot; account,
                    s/he is thereby granting permission for his/her child or children to access the
                    various Services provided, including, but not limited to, message boards, email,
                    and/or instant messaging. It is the parent&apos;s and/or legal guardian&apos;s
                    responsibility to determine whether any of the Services and/or content provided
                    are age-appropriate for his/her child.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    PRIVACY POLICY
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Every member&apos;s registration data and various other personal information are
                    strictly protected by the Bluelink Systems Online Privacy Policy (see the full
                    Privacy Policy at{" "}
                    <MKTypography
                      component="a"
                      href="/privacy"
                      variant="body2"
                      fontWeight="bold"
                      sx={{ textDecoration: "underline" }}
                    >
                      https://www.paymsp.com/privacy
                    </MKTypography>
                    ) . As a member, you herein consent to the collection and use of the information
                    provided, including the transfer of information within the United States and/or
                    other countries for storage, processing or use by Bluelink Systems and/or our
                    subsidiaries and affiliates.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Use of Information Collected
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems does not now, nor will it in the future, sell, rent or lease
                    any of its customer lists and/or names to any third parties.
                    <br />
                    Bluelink Systems may collect and may make use of personal information to assist
                    in the operation of our website and to ensure delivery of the services you need
                    and request. At times, we may find it necessary to use personally identifiable
                    information as a means to keep you informed of other possible products and/or
                    services that may be available to you from{" "}
                    <MKTypography
                      component="a"
                      href="/"
                      variant="body2"
                      fontWeight="bold"
                      sx={{ textDecoration: "underline" }}
                    >
                      https://www.paymsp.com/
                    </MKTypography>
                    <br />
                    Bluelink Systems may also be in contact with you with regards to completing
                    surveys and/or research questionnaires related to your opinion of current,
                    potential, or future services that may be offered.
                    <br />
                    Bluelink Systems may find it beneficial to all our customers to share specific
                    data with our trusted partners in an effort to conduct statistical analysis,
                    provide you with email and/or postal mail, deliver support and/or arrange for
                    deliveries to be made. Those third parties shall be strictly prohibited from
                    making use of your personal information, other than to deliver those services
                    which you requested, and as such they are required, in accordance with this
                    agreement, to maintain the strictest confidentiality with regards to all your
                    information.
                    <br />
                    Bluelink Systems uses various third-party social media features including but
                    not limited to Facebook, Twitter, Instagram and other interactive programs.
                    These may collect your IP address and require cookies to work properly. These
                    services are governed by the privacy policies of the providers and are not
                    within Bluelink Systems &apos;s control.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    MEMBER ACCOUNT, USERNAME, PASSWORD AND SECURITY
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    When you set up an account, you are the sole authorized user of your account.
                    You shall be responsible for maintaining the secrecy and confidentiality of your
                    password and for all activities that transpire on or within your account. It is
                    your responsibility for any act or omission of any user(s) that access your
                    account information that, if undertaken by you, would be deemed a violation of
                    the TOS. It shall be your responsibility to notify Bluelink Systems immediately
                    if you notice any unauthorized access or use of your account or password or any
                    other breach of security. Bluelink Systems shall not be held liable for any loss
                    and/or damage arising from any failure to comply with this term and/or condition
                    of the TOS.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    CONDUCT
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    As a user or member of the Site, you herein acknowledge, understand and agree
                    that all information, text, software, data, photographs, music, video, messages,
                    tags or any other content, whether it is publicly or privately posted and/or
                    transmitted, is the expressed sole responsibility of the individual from whom
                    the content originated. In short, this means that you are solely responsible for
                    any and all content posted, uploaded, emailed, transmitted or otherwise made
                    available by way of the PayMSP Services, and as such, we do not guarantee the
                    accuracy, integrity or quality of such content. It is expressly understood that
                    by use of our Services, you may be exposed to content including, but not limited
                    to, any errors or omissions in any content posted, and/or any loss or damage of
                    any kind incurred as a result of the use of any content posted, emailed,
                    transmitted or otherwise made available by PayMSP.
                    <br />
                    Furthermore, you herein agree not to make use of Bluelink Systems&apos;s
                    Services for the purpose of:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        uploading, posting, emailing, transmitting, or otherwise making available
                        any content that shall be deemed unlawful, harmful, threatening, abusive,
                        harassing, tortious, defamatory, vulgar, obscene, libelous, or invasive of
                        another&apos;s privacy or which is hateful, and/or racially, ethnically, or
                        otherwise objectionable;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        causing harm to minors in any manner whatsoever;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        causing harm to minors in any manner whatsoever;
                      </MKTypography>
                    </MKBox>

                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        impersonating any individual or entity, including, but not limited to, any
                        PayMSP officials, forum leaders, guides or hosts or falsely stating or
                        otherwise misrepresenting any affiliation with an individual or entity;
                      </MKTypography>
                    </MKBox>

                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        forging captions, headings or titles or otherwise offering any content that
                        you personally have no right to pursuant to any law nor having any
                        contractual or fiduciary relationship with;
                      </MKTypography>
                    </MKBox>

                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        uploading, posting, emailing, transmitting or otherwise offering any such
                        content that may infringe upon any patent, copyright, trademark, or any
                        other proprietary or intellectual rights of any other party;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        uploading, posting, emailing, transmitting or otherwise offering any content
                        that you do not personally have any right to offer pursuant to any law or in
                        accordance with any contractual or fiduciary relationship;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        uploading, posting, emailing, transmitting, or otherwise offering any
                        unsolicited or unauthorized advertising, promotional flyers, &quot;junk
                        mail,&quot; &quot;spam,&quot; or any other form of solicitation, except in
                        any such areas that may have been designated for such purpose;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        uploading, posting, emailing, transmitting, or otherwise offering any source
                        that may contain a software virus or other computer code, any files and/or
                        programs which have been designed to interfere, destroy and/or limit the
                        operation of any computer software, hardware, or telecommunication
                        equipment;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        disrupting the normal flow of communication, or otherwise acting in any
                        manner that would negatively affect other users&apos; ability to participate
                        in any real time interactions;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        intentionally or unintentionally violating any local, state, federal,
                        national or international law, including, but not limited to, rules,
                        guidelines, and/or regulations decreed by the U.S. Securities and Exchange
                        Commission, in addition to any rules of any nation or other securities
                        exchange, that would include without limitation, the New York Stock
                        Exchange, the American Stock Exchange, or the NASDAQ, and any regulations
                        having the force of law;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        providing informational support or resources, concealing and/or disguising
                        the character, location, and or source to any organization delegated by the
                        United States government as a &apos;foreign terrorist organization&apos; in
                        accordance to Section 219 of the Immigration Nationality Act;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        &quot;stalking&quot; or with the intent to otherwise harass another
                        individual; and/or
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        collecting or storing of any personal data relating to any other member or
                        user in connection with the prohibited conduct and/or activities which have
                        been set forth in the aforementioned paragraphs.
                      </MKTypography>
                    </MKBox>

                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        interfering with or disrupting any Bluelink Systems Services, servers and/or
                        networks that may be connected or related to our website, including, but not
                        limited to, the use of any device software and/or routine to bypass the
                        robot exclusion headers;
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems herein reserves the right to pre-screen, refuse and/or delete
                    any content currently available through our Services. In addition, we reserve
                    the right to remove and/or delete any such content that would violate the TOS or
                    which would otherwise be considered offensive to other visitors, users and/or
                    members.
                    <br />
                    Bluelink Systems herein reserves the right to access, preserve and/or disclose
                    member account information and/or content if it is requested to do so by law or
                    in good faith belief that any such action is deemed reasonably necessary for:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        compliance with any legal process;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        enforcement of the TOS;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        responding to any claim that therein contained content is in violation of
                        the rights of any third party;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        responding to requests for customer service; or
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        protecting the rights, property or the personal safety of Bluelink Systems,
                        its visitors, users and members, including the general public.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems herein reserves the right to include the use of security
                    components that may permit digital information or material to be protected, and
                    that such use of information and/or material is subject to usage guidelines and
                    regulations established by Bluelink Systems or any other content providers
                    supplying content services to Bluelink Systems. You are hereby prohibited from
                    making any attempt to override or circumvent any of the embedded usage rules in
                    our Services. Furthermore, unauthorized reproduction, publication, distribution,
                    or exhibition of any information or materials supplied by our Services, despite
                    whether done so in whole or in part, is expressly prohibited.
                  </MKTypography>

                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    CAUTIONS FOR GLOBAL USE AND EXPORT AND IMPORT COMPLIANCE
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Due to the global nature of the internet, through the use of our network you
                    hereby agree to comply with all local rules relating to online conduct and that
                    which is considered acceptable Content. Uploading, posting and/or transferring
                    of software, technology and other technical data may be subject to the export
                    and import laws of the United States and possibly other countries. Through the
                    use of our network, you thus agree to comply with all applicable export and
                    import laws, statutes and regulations, including, but not limited to, the Export
                    Administration Regulations (http://www.access.gpo.gov/bis/ear/ear_data.html), as
                    well as the sanctions control program of the United States
                    (http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx).
                    Furthermore, you state and pledge that you:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        are not on the list of prohibited individuals which may be identified on any
                        government export exclusion report
                        (http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm) nor a
                        member of any other government which may be part of an export-prohibited
                        country identified in applicable export and import laws and regulations;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        agree not to transfer any software, technology or any other technical data
                        through the use of our network Services to any export-prohibited country;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        agree not to use our website network Services for any military, nuclear,
                        missile, chemical or biological weaponry end uses that would be a violation
                        of the U.S. export laws; and
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        agree not to post, transfer nor upload any software, technology or any other
                        technical data which would be in violation of the U.S. or other applicable
                        export and/or import laws
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    CONTENT PLACED OR MADE AVAILABLE FOR COMPANY SERVICES
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems shall not lay claim to ownership of any content submitted by
                    any visitor, member, or user, nor make such content available for inclusion on
                    our website Services. Therefore, you hereby grant and allow for Bluelink Systems
                    the below listed worldwide, royalty-free and non-exclusive licenses, as
                    applicable:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        The content submitted or made available for inclusion on the publicly
                        accessible areas of Bluelink Systems&apos;s sites, the license provided to
                        permit to use, distribute, reproduce, modify, adapt, publicly perform and/or
                        publicly display said Content on our network Services is for the sole
                        purpose of providing and promoting the specific area to which this content
                        was placed and/or made available for viewing. This license shall be
                        available so long as you are a member of Bluelink Systems&apos;s sites, and
                        shall terminate at such time when you elect to discontinue your membership.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Photos, audio, video and/or graphics submitted or made available for
                        inclusion on the publicly accessible areas of Bluelink Systems&apos;s sites,
                        the license provided to permit to use, distribute, reproduce, modify, adapt,
                        publicly perform and/or publicly display said Content on our network
                        Services are for the sole purpose of providing and promoting the specific
                        area in which this content was placed and/or made available for viewing.
                        This license shall be available so long as you are a member of Bluelink
                        Systems&apos;s sites and shall terminate at such time when you elect to
                        discontinue your membership.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        For any other content submitted or made available for inclusion on the
                        publicly accessible areas of Bluelink Systems&apos;s sites, the continuous,
                        binding and completely sub-licensable license which is meant to permit to
                        use, distribute, reproduce, modify, adapt, publish, translate, publicly
                        perform and/or publicly display said content, whether in whole or in part,
                        and the incorporation of any such Content into other works in any
                        arrangement or medium current used or later developed.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Those areas which may be deemed &apos;publicly accessible&apos; areas of
                    Bluelink Systems&apos;s sites are those such areas of our network properties
                    which are meant to be available to the general public, and which would include
                    message boards and groups that are openly available to both users and members.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    CONTRIBUTIONS TO COMPANY WEBSITE
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems provides an area for our users and members to contribute
                    feedback to our website. When you submit ideas, documents, suggestions and/or
                    proposals (&quot;Contributions&quot;) to our site, you acknowledge and agree
                    that:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        your contributions do not contain any type of confidential or proprietary
                        information;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        PayMSP shall not be liable or under any obligation to ensure or maintain
                        confidentiality, expressed or implied, related to any Contributions;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        PayMSP shall be entitled to make use of and/or disclose any such
                        Contributions in any such manner as they may see fit;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        the contributor&apos;s Contributions shall automatically become the sole
                        property of PayMSP; and
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        PayMSP is under no obligation to either compensate or provide any form of
                        reimbursement in any manner or nature.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    INDEMNITY
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    All users and/or members herein agree to insure and hold Bluelink Systems, our
                    subsidiaries, affiliates, agents, employees, officers, partners and/or licensors
                    blameless or not liable for any claim or demand, which may include, but is not
                    limited to, reasonable attorney fees made by any third party which may arise
                    from any content a member or user of our site may submit, post, modify, transmit
                    or otherwise make available through our Services, the use of PayMSP Services or
                    your connection with these Services, your violations of the Terms of Service
                    and/or your violation of any such rights of another person.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    COMMERCIAL REUSE OF SERVICES
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    The member or user herein agrees not to replicate, duplicate, copy, trade, sell,
                    resell nor exploit for any commercial reason any part, use of, or access to
                    PayMSP&apos;s sites.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    USE AND STORAGE GENERAL PRACTICES
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    You herein acknowledge that Bluelink Systems may set up any such practices
                    and/or limits regarding the use of our Services, without limitation of the
                    maximum number of days that any email, message posting or any other uploaded
                    content shall be retained by Bluelink Systems, nor the maximum number of email
                    messages that may be sent and/or received by any member, the maximum volume or
                    size of any email message that may be sent from or may be received by an account
                    on our Service, the maximum disk space allowable that shall be allocated on
                    Bluelink Systems&apos;s servers on the member&apos;s behalf, and/or the maximum
                    number of times and/or duration that any member may access our Services in a
                    given period of time. In addition, you also agree that Bluelink Systems has
                    absolutely no responsibility or liability for the removal or failure to maintain
                    storage of any messages and/or other communications or content maintained or
                    transmitted by our Services. You also herein acknowledge that we reserve the
                    right to delete or remove any account that is no longer active for an extended
                    period of time. Furthermore, Bluelink Systems shall reserve the right to modify,
                    alter and/or update these general practices and limits at our discretion.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    MODIFICATIONS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems shall reserve the right at any time it may deem fit, to modify,
                    alter and or discontinue, whether temporarily or permanently, our service, or
                    any part thereof, with or without prior notice. In addition, we shall not be
                    held liable to you or to any third party for any such alteration, modification,
                    suspension and/or discontinuance of our Services, or any part thereof.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    TERMINATION
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    As a member of{" "}
                    <MKTypography
                      component="a"
                      href="/"
                      variant="body2"
                      fontWeight="bold"
                      sx={{ textDecoration: "underline" }}
                    >
                      https://www.paymsp.com/
                    </MKTypography>
                    , you may cancel or terminate your account, associated email address and/or
                    access to our Services by submitting a cancellation or termination request to
                    support@paymsp.com.
                    <br />
                    As a member, you agree that Bluelink Systems may, without any prior written
                    notice, immediately suspend, terminate, discontinue and/or limit your account,
                    any email associated with your account, and access to any of our Services. The
                    cause for such termination, discontinuance, suspension and/or limitation of
                    access shall include, but is not limited to:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        any breach or violation of our TOS or any other incorporated agreement,
                        regulation and/or guideline;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        by way of requests from law enforcement or any other governmental agencies;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        the discontinuance, alteration and/or material modification to our Services,
                        or any part thereof;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        unexpected technical or security issues and/or problems;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        any extended periods of inactivity;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        any engagement by you in any fraudulent or illegal activities; and/or
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        the nonpayment of any associated fees that may be owed by you in connection
                        with your{" "}
                        <MKTypography
                          component="a"
                          href="/"
                          variant="body2"
                          fontWeight="bold"
                          sx={{ textDecoration: "underline" }}
                        >
                          https://www.paymsp.com/
                        </MKTypography>
                        account Services.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Furthermore, you herein agree that any and all terminations, suspensions,
                    discontinuances, and or limitations of access for cause shall be made at our
                    sole discretion and that we shall not be liable to you or any other third party
                    with regards to the termination of your account, associated email address and/or
                    access to any of our Services.
                    <br />
                    The termination of your account with{" "}
                    <MKTypography
                      component="a"
                      href="/"
                      variant="body2"
                      fontWeight="bold"
                      sx={{ textDecoration: "underline" }}
                    >
                      https://www.paymsp.com/
                    </MKTypography>
                    shall include any and/or all of the following:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        the removal of any access to all or part of the Services offered within
                        <MKTypography
                          component="a"
                          href="/"
                          variant="body2"
                          fontWeight="bold"
                          color="white"
                        >
                          https://www.paymsp.com/
                        </MKTypography>
                        ;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        the deletion of your password and any and all related information, files,
                        and any such content that may be associated with or inside your account, or
                        any part thereof; and
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        the barring of any further use of all or part of our Services.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    MODIFICATIONS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems shall reserve the right at any time it may deem fit, to modify,
                    alter and or discontinue, whether temporarily or permanently, our service, or
                    any part thereof, with or without prior notice. In addition, we shall not be
                    held liable to you or to any third party for any such alteration, modification,
                    suspension and/or discontinuance of our Services, or any part thereof.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    ADVERTISERS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Any correspondence or business dealings with, or the participation in any
                    promotions of, advertisers located on or through our Services, which may include
                    the payment and/or delivery of such related goods and/or Services, and any such
                    other term, condition, warranty and/or representation associated with such
                    dealings, are and shall be solely between you and any such advertiser. Moreover,
                    you herein agree that Bluelink Systems shall not be held responsible or liable
                    for any loss or damage of any nature or manner incurred as a direct result of
                    any such dealings or as a result of the presence of such advertisers on our
                    website.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    LINKS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Either Bluelink Systems or any third parties may provide links to other websites
                    and/or resources. Thus, you acknowledge and agree that we are not responsible
                    for the availability of any such external sites or resources, and as such, we do
                    not endorse nor are we responsible or liable for any content, products,
                    advertising or any other materials, on or available from such third party sites
                    or resources. Furthermore, you acknowledge and agree that Bluelink Systems shall
                    not be responsible or liable, directly or indirectly, for any such damage or
                    loss which may be a result of, caused or allegedly to be caused by or in
                    connection with the use of or the reliance on any such content, goods or
                    Services made available on or through any such site or resource.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    PROPRIETARY RIGHTS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    You do hereby acknowledge and agree that Bluelink Systems&apos;s Services and
                    any essential software that may be used in connection with our Services
                    (&quot;Software(&quot;) shall contain proprietary and confidential material that
                    is protected by applicable intellectual property rights and other laws.
                    Furthermore, you herein acknowledge and agree that any Content which may be
                    contained in any advertisements or information presented by and through our
                    Services or by advertisers is protected by copyrights, trademarks, patents or
                    other proprietary rights and laws. Therefore, except for that which is expressly
                    permitted by applicable law or as authorized by Bluelink Systems or such
                    applicable licensor, you agree not to alter, modify, lease, rent, loan, sell,
                    distribute, transmit, broadcast, publicly perform and/or created any
                    plagiaristic works which are based on Bluelink Systems Services (e.g. Content or
                    Softwar, in whole or part.
                    <br />
                    Bluelink Systems herein has granted you personal, non-transferable and
                    non-exclusive rights and/or license to make use of the object code or our
                    Software on a single computer, as long as you do not, and shall not, allow any
                    third party to duplicate, alter, modify, create or plagiarize work from, reverse
                    engineer, reverse assemble or otherwise make an attempt to locate or discern any
                    source code, sell, assign, sublicense, grant a security interest in and/or
                    otherwise transfer any such right in the Software. Furthermore, you do herein
                    agree not to alter or change the Software in any manner, nature or form, and as
                    such, not to use any modified versions of the Software, including and without
                    limitation, for the purpose of obtaining unauthorized access to our Services.
                    Lastly, you also agree not to access or attempt to access our Services through
                    any means other than through the interface which is provided by Bluelink Systems
                    for use in accessing our Services.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    WARRANTY DISCLAIMERS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        THE USE OF BLUELINK SYSTEMS SERVICES AND SOFTWARE ARE AT THE SOLE RISK BY
                        YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN &quot;AS IS&quot;
                        AND/OR &quot;AS AVAILABLE&quot; BASIS. BLUELINK SYSTEMS AND OUR
                        SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
                        LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND WHETHER
                        EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES
                        OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                        NON-INFRINGEMENT.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        BLUELINK SYSTEMS AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS
                        AND LICENSORS MAKE NO SUCH WARRANTIES THAT (i) BLUELINK SYSTEMS SERVICES OR
                        SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) BLUELINK SYSTEMS SERVICES OR
                        SOFTWARE SHALL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THAT
                        SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE BLUELINK SYSTEMS
                        SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF ANY
                        PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL WHICH MAY BE PURCHASED
                        OR OBTAINED BY YOU THROUGH OUR SERVICES OR SOFTWARE WILL MEET YOUR
                        EXPECTATIONS; AND (v) THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL
                        BE CORRECTED.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF
                        BLUELINK SYSTEMS SERVICES OR SOFTWARE SHALL BE ACCESSED BY YOUR SOLE
                        DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY RESPONSIBLE FOR
                        AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY
                        DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR
                        DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF
                        ANY SUCH INFORMATION OR MATERIAL.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        NO ADVICE AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL, THAT MAY BE
                        OBTAINED BY YOU FROM BLUELINK SYSTEMS OR BY WAY OF OR FROM OUR SERVICES OR
                        SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        A SMALL PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME DEGREE OF EPILEPTIC
                        SEIZURE WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS THAT MAY BE
                        CONTAINED ON A COMPUTER SCREEN OR WHILE USING OUR SERVICES. CERTAIN
                        CONDITIONS MAY INDUCE A PREVIOUSLY UNKNOWN CONDITION OR UNDETECTED EPILEPTIC
                        SYMPTOM IN USERS WHO HAVE SHOWN NO HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY.
                        SHOULD YOU, ANYONE YOU KNOW OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC
                        CONDITION, PLEASE CONSULT A PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING
                        SYMPTOMS WHILE USING OUR SERVICES: DIZZINESS, ALTERED VISION, EYE OR MUSCLE
                        TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR
                        CONVULSIONS.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    LIMITATION OF LIABILITY
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT BLUELINK SYSTEMS AND OUR
                    SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
                    SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH
                    MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA AND/OR OTHER
                    INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT
                    SAID DAMAGES MAY OCCUR, AND RESULT FROM:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        THE USE OR INABILITY TO USE OUR SERVICE;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    RELEASE
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    In the event you have a dispute, you agree to release Bluelink Systems (and its
                    officers, directors, employees, agents, parent subsidiaries, affiliates,
                    co-branders, partners and any other third parties) from claims, demands and
                    damages (actual and consequential) of every kind and nature, known and unknown,
                    suspected or unsuspected, disclosed and undisclosed, arising out of or in any
                    way connected to such dispute.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    SPECIAL ADMONITION RELATED TO FINANCIAL MATTERS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Should you intend to create or to join any service, receive or request any such
                    news, messages, alerts or other information from our Services concerning
                    companies, stock quotes, investments or securities, please review the above
                    Sections Warranty Disclaimers and Limitations of Liability again. In addition,
                    for this particular type of information, the phrase &apos;Let the investor
                    beware&apos; is appropriate. Bluelink Systems&apos;s content is provided
                    primarily for informational purposes, and no content that shall be provided or
                    included in our Services is intended for trading or investing purposes. Bluelink
                    Systems and our licensors shall not be responsible or liable for the accuracy,
                    usefulness or availability of any information transmitted and/or made available
                    by way of our Services, and shall not be responsible or liable for any trading
                    and/or investment decisions based on any such information.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    EXCLUSION AND LIMITATIONS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    THERE ARE SOME JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION OF CERTAIN
                    WARRANTIES OR THE LIMITATION OF EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS OF SECTIONS
                    WARRANTY DISCLAIMERS AND LIMITATION OF LIABILITY MAY NOT APPLY TO YOU.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    THIRD PARTY BENEFICIARIES
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    You herein acknowledge, understand and agree, unless otherwise expressly
                    provided in this TOS, that there shall be no third-party beneficiaries to this
                    agreement.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    NOTICE
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems may furnish you with notices, including those with regards to
                    any changes to the TOS, including but not limited to email, regular mail, MMS or
                    SMS, text messaging, postings on our website Services, or other reasonable means
                    currently known or any which may be herein after developed. Any such notices may
                    not be received if you violate any aspects of the TOS by accessing our Services
                    in an unauthorized manner. Your acceptance of this TOS constitutes your
                    agreement that you are deemed to have received any and all notices that would
                    have been delivered had you accessed our Services in an authorized manner.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    TRADEMARK INFORMATION
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    You herein acknowledge, understand and agree that all of the Bluelink Systems
                    trademarks, copyright, trade name, service marks, and other Bluelink Systems
                    logos and any brand features, and/or product and service names are trademarks
                    and as such, are and shall remain the property of Bluelink Systems. You herein
                    agree not to display and/or use in any manner the Bluelink Systems logo or marks
                    without obtaining Bluelink Systems&apos;s prior written consent.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS NOTICE & PROCEDURES
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems will always respect the intellectual property of others, and we
                    ask that all of our users do the same. With regards to appropriate circumstances
                    and at its sole discretion, Bluelink Systems may disable and/or terminate the
                    accounts of any user who violates our TOS and/or infringes the rights of others.
                    If you feel that your work has been duplicated in such a way that would
                    constitute copyright infringement, or if you believe your intellectual property
                    rights have been otherwise violated, you should provide to us the following
                    information:
                  </MKTypography>
                  <MKBox
                    component="ol"
                    my={3}
                    ml={6}
                    sx={{ listStyleType: "lower-alpha !important", fontSize: "1rem" }}
                  >
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        The electronic or the physical signature of the individual that is
                        authorized on behalf of the owner of the copyright or other intellectual
                        property interest;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        A description of the copyrighted work or other intellectual property that
                        you believe has been infringed upon;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        A description of the location of the site which you allege has been
                        infringing upon your work;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Your physical address, telephone number, and email address;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        A statement, in which you state that the alleged and disputed use of your
                        work is not authorized by the copyright owner, its agents or the law;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        And finally, a statement, made under penalty of perjury, that the
                        aforementioned information in your notice is truthful and accurate, and that
                        you are the copyright or intellectual property owner, representative or
                        agent authorized to act on the copyright or intellectual property
                        owner&apos;s behalf.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    The Bluelink Systems Agent for notice of claims of copyright or other
                    intellectual property infringement can be contacted as follows:
                    <br />
                    Mailing Address:
                    <br />
                    Bluelink Systems
                    <br />
                    Attn: Copyright Agent
                    <br />
                    13809 N Highway 183 Ste 500K
                    <br />
                    Austin, Texas 78750
                    <br />
                    Telephone: 5127179090
                    <br />
                    Email: info@paymsp.com
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    CLOSED CAPTIONING
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    BE IT KNOWN, that Bluelink Systems complies with all applicable Federal
                    Communications Commission rules and regulations regarding the closed captioning
                    of video content. For more information, please visit our website at{" "}
                    <MKTypography
                      component="a"
                      href="/"
                      variant="body2"
                      fontWeight="bold"
                      sx={{ textDecoration: "underline" }}
                    >
                      https://www.paymsp.com/
                    </MKTypography>
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    GENERAL INFORMATION
                  </MKTypography>
                  <MKTypography variant="h5" mt={2} mb={1} sx={{ textDecoration: "underline" }}>
                    ENTIRE AGREEMENT
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    This TOS constitutes the entire agreement between you and Bluelink Systems and
                    shall govern the use of our Services, superseding any prior version of this TOS
                    between you and us with respect to Bluelink Systems Services. You may also be
                    subject to additional terms and conditions that may apply when you use or
                    purchase certain other Bluelink Systems Services, affiliate Services,
                    third-party content or third-party software.
                  </MKTypography>
                  <MKTypography variant="h5" mt={2} mb={1} sx={{ textDecoration: "underline" }}>
                    CHOICE OF LAW AND FORUM
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    It is at the mutual agreement of both you and Bluelink Systems with regard to
                    the TOS that the relationship between the parties shall be governed by the laws
                    of the state of Texas without regard to its conflict of law provisions and that
                    any and all claims, causes of action and/or disputes, arising out of or relating
                    to the TOS, or the relationship between you and Bluelink Systems, shall be filed
                    within the courts having jurisdiction within the County of USA, Texas or the
                    U.S. District Court located in said state. You and Bluelink Systems agree to
                    submit to the jurisdiction of the courts as previously mentioned, and agree to
                    waive any and all objections to the exercise of jurisdiction over the parties by
                    such courts and to venue in such courts.
                  </MKTypography>
                  <MKTypography variant="h5" mt={2} mb={1} sx={{ textDecoration: "underline" }}>
                    WAIVER AND SEVERABILITY OF TERMS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    At any time, should Bluelink Systems fail to exercise or enforce any right or
                    provision of the TOS, such failure shall not constitute a waiver of such right
                    or provision. If any provision of this TOS is found by a court of competent
                    jurisdiction to be invalid, the parties nevertheless agree that the court should
                    endeavor to give effect to the parties&apos; intentions as reflected in the
                    provision, and the other provisions of the TOS remain in full force and effect.
                  </MKTypography>
                  <MKTypography variant="h5" mt={2} mb={1} sx={{ textDecoration: "underline" }}>
                    NO RIGHT OF SURVIVORSHIP NON-TRANSFERABILITY
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    You acknowledge, understand and agree that your account is non-transferable and
                    any rights to your ID and/or contents within your account shall terminate upon
                    your death. Upon receipt of a copy of a death certificate, your account may be
                    terminated and all contents therein permanently deleted.
                  </MKTypography>
                  <MKTypography variant="h5" mt={2} mb={1} sx={{ textDecoration: "underline" }}>
                    STATUTE OF LIMITATIONS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    You acknowledge, understand and agree that regardless of any statute or law to
                    the contrary, any claim or action arising out of or related to the use of our
                    Services or the TOS must be filed within 4 year(s) after said claim or cause of
                    action arose or shall be forever barred.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    VIOLATIONS
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Please report any and all violations of this TOS to Bluelink Systems as follows:
                    <br />
                    Mailing Address:
                    <br /> Bluelink Systems
                    <br />
                    13809 N Highway 183 Ste 500K Austin, Texas 78750
                    <br />
                    Telephone: 5127179090
                    <br />
                    Email: info@paymsp.com
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TermsofUse;
