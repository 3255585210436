import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Rental page components
import FaqCollapse from "pages/LandingPages/Rental/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" id="support" pt={14}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                If you have questions not covered below, please reach out to us, and we’ll get you
                answers quickly
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="How do I start?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              You are ready to start if you are on one of our supported PSAs and Accounting
              platform. Click the signup button and register.
            </FaqCollapse>
            <FaqCollapse
              title="Can you provide me with a payment gateway?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              Yes, we partner with a few payment processors and merchant account providers. We can
              get you set up from end to end.
            </FaqCollapse>
            <FaqCollapse
              title="Can I bring my own gateway?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Yes, we support this as much as possible. There may be development costs involved in
              integrating your payment gateway.
            </FaqCollapse>
            <FaqCollapse
              title="Do you support ACH payments?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Absolutely!
            </FaqCollapse>
            <FaqCollapse
              title="Can you implement a feature I need?"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              It is our pleasure to serve. Please send us a note on what you need. We’ll get to it
              ASAP.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
