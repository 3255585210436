// Pages

import PriceChangeIcon from "@mui/icons-material/PriceChange";

import Xero from "layouts/pages/integrations/xero";
// import Wepay from "layouts/pages/integrations/wepay";
import Stripe from "layouts/pages/integrations/stripe";
import Autotask from "layouts/pages/integrations/autotask";
import Quickbook from "layouts/pages/integrations/quickbook";
import Authorize from "layouts/pages/integrations/authorize";
import Connectwise from "layouts/pages/integrations/connectwise";

// icon
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

import XeroLogo from "assets/images/logos/xero-logo.png";
// import WepayLogo from "assets/images/integration-logos/wepay.png";
import AutotaskLogo from "assets/images/logos/autotask-square-logo.png";
import StripeLogo from "assets/images/integration-logos/stripe-logo.svg";
import QuickbookLogo from "assets/images/logos/quickbook-square-logo.png";
import ConnectwiseLogo from "assets/images/logos/connectwise-square-logo.png";
import authorizeNetLogo from "assets/images/integration-logos/authorize-net.png";

import MKBox from "components/MKBox";

const routes = [
  {
    name: "features",
    icon: <FeaturedPlayListIcon />,
    route: "/#features",
  },
  {
    name: "Pricing",
    icon: <PriceChangeIcon />,
    route: "/#pricing",
  },
  {
    name: "integrations",
    icon: <IntegrationInstructionsIcon />,
    route: "/#integrations",
    dropdown: true,
    collapse: [
      {
        name: "QuickBooks",
        icon: <MKBox component="img" src={QuickbookLogo} sx={{ width: "2rem" }} mr={1} />,
        route: "/integration/quickbook",
        component: <Quickbook />,
      },
      {
        name: "Xero",
        route: "/integration/xero",
        icon: <MKBox component="img" src={XeroLogo} sx={{ height: "2rem" }} mr={1} />,
        component: <Xero />,
      },
      {
        name: "Connectwise",
        route: "/integration/connectwise",
        component: <Connectwise />,
        icon: <MKBox component="img" src={ConnectwiseLogo} sx={{ height: "2rem" }} mr={1} />,
      },
      {
        name: "Autotask",
        route: "/integration/autotask",
        component: <Autotask />,
        icon: <MKBox component="img" src={AutotaskLogo} sx={{ height: "2rem" }} mr={1} />,
      },
      {
        name: "Authorize.net",
        route: "/integration/authorize",
        component: <Authorize />,
        icon: <MKBox component="img" src={authorizeNetLogo} sx={{ height: "1.5rem" }} mr={1} />,
      },
      // {
      //   name: "Wepay",
      //   route: "/integration/wepay",
      //   component: <Wepay />,
      //   icon: <MKBox component="img" src={WepayLogo} sx={{ height: "1.5rem" }} mr={1} />,
      // },
      {
        name: "Stripe",
        route: "/integration/stripe",
        component: <Stripe />,
        icon: <MKBox component="img" src={StripeLogo} sx={{ height: "1.5rem" }} mr={1} />,
      },
    ],
  },
  {
    name: "Support",
    icon: <ContactSupportIcon />,
    route: "/#support",
  },
];

export default routes;
