import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import { ENV } from "constant";

function Pricing() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 7 }} id="pricing">
      <MKBox
        borderRadius="xl"
        shadow="lg"
        sx={{
          height: pxToRem(240),
          backgroundColor: colors.black.main,
        }}
      />
      <MKBox mt={-16}>
        <Container>
          <Grid container sx={{ mb: 6 }}>
            <Grid item xs={7} md={6} lg={4} sx={{ mx: "auto", textAlign: "center" }}>
              <AppBar position="static">
                <Tabs value={activeTab} onChange={handleTabType}>
                  <Tab
                    id="monthly"
                    label={
                      <MKBox py={0.5} px={2} color="inherit">
                        Monthly
                      </MKBox>
                    }
                  />
                  <Tab
                    id="annual"
                    label={
                      <MKBox py={0.5} px={2} color="inherit">
                        Annual
                      </MKBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  sx={{ height: "100%" }}
                  badge={{ color: "light", label: "starter" }}
                  price={{ value: "Free" }}
                  specifications={[
                    { label: "Up to 5 customers", includes: true },
                    { label: "Customer portal", includes: true },
                    { label: "Saved payment information", includes: true },
                    { label: "Autopay rulesets", includes: true },
                    { label: "ACH payments", includes: true },
                    { label: "Invoice notifications", includes: true },
                    { label: "Payment reminder emails", includes: true },
                    { label: "Free onboarding", includes: true },
                    { label: "Unlimited QBO Sync ($150 per month savings)", includes: true },
                    { label: "Unlimited Xero Sync ($150 per month savings)", includes: true },
                    { label: "Gateway provided", includes: true },
                  ]}
                  action={{
                    type: "external",
                    route: `${ENV.ADMIN}sign-up`,
                    color: "dark",
                    label: "Sign up",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  sx={{ height: "100%" }}
                  color="dark"
                  badge={{ color: "info", label: "premium" }}
                  price={{ currency: "$", value: tabType === "annual" ? 149 : 160, type: "mo" }}
                  specifications={[
                    { label: "Unlimited customers", includes: true },
                    { label: "Customer portal", includes: true },
                    { label: "Saved payment information", includes: true },
                    { label: "Autopay rulesets", includes: true },
                    { label: "ACH payments", includes: true },
                    { label: "Invoice notifications", includes: true },
                    { label: "Payment reminder emails", includes: true },
                    { label: "Free onboarding", includes: true },
                    { label: "Unlimited QBO Sync ($150 per month savings)", includes: true },
                    { label: "Unlimited Xero Sync ($150 per month savings)", includes: true },
                    { label: "Gateway provided", includes: true },
                    { label: "White Label", includes: true },
                  ]}
                  action={{
                    type: "external",
                    route: `${ENV.ADMIN}sign-up`,
                    color: "info",
                    label: "Sign up",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  sx={{ height: "100%" }}
                  badge={{ color: "light", label: "enterprise" }}
                  price={{ currency: "$", value: tabType === "annual" ? 399 : 450, type: "mo" }}
                  specifications={[
                    { label: "Unlimited customers", includes: true },
                    { label: "Customer portal", includes: true },
                    { label: "Saved payment information", includes: true },
                    { label: "Autopay rulesets", includes: true },
                    { label: "ACH payments", includes: true },
                    { label: "Invoice notifications", includes: true },
                    { label: "Payment reminder emails", includes: true },
                    { label: "Premium onboarding", includes: true },
                    { label: "Unlimited QBO Sync ($150 per month savings)", includes: true },
                    { label: "Unlimited Xero Sync ($150 per month savings)", includes: true },
                    { label: "Bring Your Own Gateway (BYOG)", includes: true },
                    { label: "White Label", includes: true },
                  ]}
                  action={{
                    type: "external",
                    route: `${ENV.ADMIN}sign-up`,
                    color: "dark",
                    label: "Sign up",
                  }}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Pricing;
