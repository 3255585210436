import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CenteredFooter from "components/Footer/CenteredFooter";
import NavBar from "components/Navbar";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { ENV } from "constant";

import routes from "routes";
import bgImage from "assets/images/bg-presentation.png";
import MKButton from "components/MKButton";
import colors from "assets/theme/base/colors";
import Faq from "./sections/Faq";
import Logo from "./sections/Logo";
import Pricing from "./sections/Pricing";
import Information from "./sections/Information";
import AboutUs from "./sections/AboutUs";

function Presentation() {
  return (
    <>
      <NavBar routes={routes} sticky />

      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container>
          <MKTypography
            variant="h1"
            mt={-6}
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
              color: `${colors.black.main} !important`,
            })}
          >
            Bill, Sync, Get Paid
          </MKTypography>
          <MKTypography variant="body1" sx={{ color: `${colors.black.main} !important` }}>
            Fix Your CashFlow, Get Paid on Time with Autopay
          </MKTypography>
          <MKButton
            key="cash-flow-start"
            color="info"
            component="a"
            href={`${ENV.ADMIN}sign-up`}
            sx={{ mt: 2, width: "fit-content" }}
          >
            Get started
          </MKButton>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <AboutUs />
        <Information />
        <Logo />
        <Pricing />
        <Faq />
        <MKBox pt={6} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h5" color="dark">
                  Stop the awakward collection calls and late payments.
                </MKTypography>
                <MKTypography variant="h5" color="dark">
                  Let&apos;s get your clients setup with Autopay
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKButton key="started" component="a" href={`${ENV.ADMIN}sign-up`} color="info">
                  Get started
                </MKButton>
                <MKButton
                  key="about-us"
                  component="a"
                  href="https://paymsp.freshdesk.com/support/tickets/new"
                  variant="outlined"
                  color="info"
                  sx={{ ml: 2 }}
                >
                  Contact us
                </MKButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default Presentation;
