// core components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import NavBar from "components/Navbar";
import CenteredFooter from "components/Footer/CenteredFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Privacy() {
  return (
    <>
      <NavBar routes={routes} sticky />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Privacy & Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    August 1, 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="body2" mt={6} mb={3} color="text">
                    Bluelink Systems ( PayMSP) values its users&apos; privacy. This Privacy Policy
                    (&quot;Policy&quot;) will help you understand how we collect and use personal
                    information from those who visit our website or make use of our online
                    facilities and services, and what we will and will not do with the information
                    we collect. Our Policy has been designed and created to assure those affiliated
                    with Bluelink Systems of our commitment and realization of our obligation not
                    only to meet, but to exceed, most existing privacy standards.
                    <br />
                    We reserve the right to make changes to this Policy at any given time. If you
                    have not registered with us and want to make sure that you are up to date with
                    the latest changes, we advise you to frequently visit this page. If you are a
                    registered user, we will notify you via email of any changes or updates made to
                    this Policy. If at any point in time Bluelink Systems decides to make use of any
                    personally identifiable information on file, in a manner vastly different from
                    that which was stated when this information was initially collected, the user or
                    users shall be promptly notified by email. Users at that time shall have the
                    option as to whether to permit the use of their information in this separate
                    manner.
                    <br />
                    This Policy applies to Bluelink Systems , and it governs any and all data
                    collection and usage by us. Through the use of www.paymsp.com, you are therefore
                    consenting to the data collection procedures expressed in this Policy.
                    <br />
                    Please note that this Policy does not govern the collection and use of
                    information by companies that Bluelink Systems does not control, nor by
                    individuals not employed or managed by us. If you visit a website that we
                    mention or link to, be sure to review its privacy policy before providing the
                    site with information. It is highly recommended and suggested that you review
                    the privacy policies and conditions of any website you choose to use or frequent
                    to better understand the way in which websites garner, make use of and share the
                    information collected
                    <br />
                    Specifically, this Policy will inform you of the following
                    <br />
                    1. What personally identifiable information is collected from you through our
                    website;
                    <br />
                    2. Why we collect personally identifiable information and the legal basis for
                    such collection;
                    <br />
                    3. How we use the collected information and with whom it may be shared;
                    <br />
                    4. What choices are available to you regarding the use of your data; and
                    <br />
                    5. The security procedures in place to protect the misuse of your information.
                    <br />
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Information We Collect
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    It is always up to you whether to disclose personally identifiable information
                    to us, although if you elect not to do so, we reserve the right not to register
                    you as a user or provide you with any products or services. This website
                    collects various types of information, such as:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Voluntarily provided information which may include your name, address, email
                        address, billing and/or credit card information etc. which may be used when
                        you purchase products and/or services and to deliver the services you have
                        requested.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Information automatically collected when visiting our website, which may
                        include cookies, third party tracking technologies and server logs.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    In addition, Bluelink Systems may have the occasion to collect non-personal
                    anonymous demographic information, such as age, gender, household income,
                    political affiliation, race and religion, as well as the type of browser you are
                    using, IP address, or type of operating system, which will assist us in
                    providing and maintaining superior quality service.
                    <br />
                    Bluelink Systems may also deem it necessary, from time to time, to follow
                    websites that our users may frequent to gleam what types of services and
                    products may be the most popular to customers or the general public.
                    <br />
                    Please rest assured that this site will only collect personal information that
                    you knowingly and willingly provide to us by way of surveys, completed
                    membership forms, and emails. It is the intent of this site to use personal
                    information only for the purpose for which it was requested, and any additional
                    uses specifically provided for in this Policy.
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    You must not:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Republish material from Creative Tim
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Sell, rent or sub-license material from Creative Tim
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Reproduce, duplicate or copy material from Creative Tim
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Redistribute content from Creative Tim
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    Why We Collect Information and For How Long
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We are collecting your data for several reasons:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To better understand your needs and provide you with the services you have
                        requested;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To fulfill our legitimate interest in improving our services and products;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To fulfill our legitimate interest in improving our services and products;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To contact you to fill out surveys or participate in other types of market
                        research, when we have your consent to do so;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To customize our website according to your online behavior and personal
                        preferences.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    The data we collect from you will be stored for no longer than necessary. The
                    length of time we retain said information will be determined based upon the
                    following criteria: the length of time your personal information remains
                    relevant; the length of time it is reasonable to keep records to demonstrate
                    that we have fulfilled our duties and obligations; any limitation periods within
                    which claims might be made; any retention periods prescribed by law or
                    recommended by regulators, professional bodies or associations; the type of
                    contract we have with you, the existence of your consent, and our legitimate
                    interest in keeping such information as stated in this Policy.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Use of Information Collected
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems does not now, nor will it in the future, sell, rent or lease
                    any of its customer lists and/or names to any third parties.
                    <br />
                    Bluelink Systems may collect and may make use of personal information to assist
                    in the operation of our website and to ensure delivery of the services you need
                    and request. At times, we may find it necessary to use personally identifiable
                    information as a means to keep you informed of other possible products and/or
                    services that may be available to you from www.paymsp.com
                    <br />
                    Bluelink Systems may also be in contact with you with regards to completing
                    surveys and/or research questionnaires related to your opinion of current,
                    potential, or future services that may be offered.
                    <br />
                    Bluelink Systems may find it beneficial to all our customers to share specific
                    data with our trusted partners in an effort to conduct statistical analysis,
                    provide you with email and/or postal mail, deliver support and/or arrange for
                    deliveries to be made. Those third parties shall be strictly prohibited from
                    making use of your personal information, other than to deliver those services
                    which you requested, and as such they are required, in accordance with this
                    agreement, to maintain the strictest confidentiality with regards to all your
                    information.
                    <br />
                    Bluelink Systems uses various third-party social media features including but
                    not limited to Facebook, Twitter, Instagram and other interactive programs.
                    These may collect your IP address and require cookies to work properly. These
                    services are governed by the privacy policies of the providers and are not
                    within Bluelink Systems &apos;s control.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Disclosure of Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems may not use or disclose the information provided by you except
                    under the following circumstances:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        as necessary to provide services or products you have ordered;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        in other ways described in this Policy or to which you have otherwise
                        consented;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        in the aggregate with other information in such a way so that your identity
                        cannot reasonably be determined;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        as required by law, or in response to a subpoena or search warrant;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        to outside auditors who have agreed to keep the information confidential;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        as necessary to enforce the Terms of Service on our website;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        as necessary to maintain, safeguard and preserve all the rights and property
                        of Bluelink Systems .
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Disclosure of Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems may not use or disclose the information provided by you except
                    under the following circumstances:
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Non-Marketing Purposes
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems greatly respects your privacy. We do maintain and reserve the
                    right to contact you if needed for non-marketing purposes (such as bug alerts,
                    security breaches, account issues, and/or changes in Bluelink Systems products
                    and services, or changes to this Policy). In certain circumstances, we may use
                    our website, newspapers, or other public means to post a notice.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Children under the age of 13
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems &apos;s website is not directed to, and does not knowingly
                    collect personal identifiable information from, children under the age of
                    thirteen (13). If it is determined that such information has been inadvertently
                    collected on anyone under the age of thirteen (13), we shall immediately take
                    the necessary steps to ensure that such information is deleted from our
                    system&apos;s database, or in the alternative, that verifiable parental consent
                    is obtained for the use and storage of such information. Anyone under the age of
                    thirteen (13) must seek and obtain parent or guardian permission to use this
                    website.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Unsubscribe or Opt-Out
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    All users and visitors to our website have the option to discontinue receiving
                    communications from us by way of email or newsletters. To discontinue or
                    unsubscribe from our website please send an email that you wish to unsubscribe
                    to unsubscribe@paymsp.com. If you wish to unsubscribe or opt-out from any
                    third-party websites, you must go to that specific website to unsubscribe or
                    opt-out. Bluelink Systems will continue to adhere to this Policy with respect to
                    any personal information previously collected.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Links to Other Websites
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Our website does contain links to affiliate and other websites. Bluelink Systems
                    does not claim nor accept responsibility for any privacy policies, practices
                    and/or procedures of other websites. Therefore, we encourage all users and
                    visitors to be aware when they leave our website and to read the privacy
                    statements of every website that collects personally identifiable information.
                    This Policy applies only and solely to the information collected by our website.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Notice to European Union Users
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems &apos;s operations are located primarily in the United States.
                    If you provide information to us, the information will be transferred out of the
                    European Union (EU) and sent to the United States. (The adequacy decision on the
                    EU-US Privacy became operational on August 1, 2016. This framework protects the
                    fundamental rights of anyone in the EU whose personal data is transferred to the
                    United States for commercial purposes. It allows the free transfer of data to
                    companies that are certified in the US under the Privacy Shield.) By providing
                    personal information to us, you are consenting to its storage and use as
                    described in this Policy.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Security
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Bluelink Systems takes precautions to protect your information. When you submit
                    sensitive information via the website, your information is protected both online
                    and offline. Wherever we collect sensitive information (e.g. credit card
                    information), that information is encrypted and transmitted to us in a secure
                    way. You can verify this by looking for a lock icon in the address bar and
                    looking for &quot;https&quot; at the beginning of the address of the webpage.
                    <br />
                    While we use encryption to protect sensitive information transmitted online, we
                    also protect your information offline. Only employees who need the information
                    to perform a specific job (for example, billing or customer service) are granted
                    access to personally identifiable information. The computers and servers in
                    which we store personally identifiable information are kept in a secure
                    environment. This is all done to prevent any loss, misuse, unauthorized access,
                    disclosure or modification of the user&apos;s personal information under our
                    control.
                    <br />
                    The Company also uses Secure Socket Layer (SSL) for authentication and private
                    communications to build users&apos; trust and confidence in the internet and
                    website use by providing simple and secure access and communication of credit
                    card and personal information.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Acceptance of Terms
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    By using this website, you are hereby accepting the terms and conditions
                    stipulated within this Privacy Policy Agreement. If you are not in agreement
                    with our terms and conditions, then you should refrain from further use of our
                    sites. In addition, your continued use of our website following proper
                    notification or the posting of any updates or changes to our terms and
                    conditions, shall mean that you agree and accept such changes.
                  </MKTypography>
                  {/*  */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How to Contact Us
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    If you have any questions or concerns regarding this Privacy Policy as it
                    relates to our website, please feel free to contact us at the following email,
                    telephone number or mailing address:
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular" mt={4} mb={2}>
                    <strong>Email:</strong> info@paymsp.com
                    <br />
                    <strong>Telephone Number:</strong> 5127179090
                    <br />
                    <strong> Mailing Address: </strong>Bluelink Systems 13809 N Highway 183 Ste 500K
                    Austin, Texas 78750
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
