// core components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import NavBar from "components/Navbar";
import CenteredFooter from "components/Footer/CenteredFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import JCBCard from "assets/images/card/jcb.png";
import IPayCard from "assets/images/card/ipay.png";
import VisaCard from "assets/images/card/visa.png";
import ECheckCard from "assets/images/card/echeck.png";
import PaypalCard from "assets/images/card/paypal.png";
import Mastercard from "assets/images/card/mastercard.png";
import DiscoveryCard from "assets/images/card/discovery.png";
import AmericanExpressCard from "assets/images/card/american-express.png";

import arrowIcon from "assets/images/arrow.png";
import brandBlue from "assets/images/logos/logo.png";
import backgroundImg from "assets/images/background-integration.png";
import authorizeNetLogo from "assets/images/integration-logos/authorize-net.png";

function Authorize() {
  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <NavBar routes={routes} transparent relative />
      </MKBox>

      <MKBox
        height="fit-content"
        component="img"
        src={backgroundImg}
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          py: 5,
          px: 2,
          mx: { xs: 2, lg: 3 },
          my: 4,
          mt: -8,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <MKTypography
            variant="h1"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
              textAlign: "center",
            })}
          >
            Authorize.net Integration Features
          </MKTypography>
          <MKBox my={3} display="flex" alignItems="center" justifyContent="center">
            <MKBox
              component="img"
              src={brandBlue}
              alt="quickbook"
              height="100%"
              sx={{ width: { md: "14rem", xs: "7rem" } }}
            />
            <MKBox
              component="img"
              src={arrowIcon}
              alt="quickbook"
              sx={{ width: { md: "3rem", xs: "2rem" }, mx: "2rem" }}
              height="100%"
            />
            <MKBox
              component="img"
              src={authorizeNetLogo}
              alt="quickbook"
              height="100%"
              sx={{ width: { md: "20rem", xs: "7rem" } }}
            />
          </MKBox>
        </Container>
        <MKBox component="section" py={1} mt={3}>
          <Container>
            <MKBox my={10}>
              <Grid container spacing={3} item xs={12} lg={8} mx="auto" sx={{ display: "block" }}>
                <MKTypography
                  variant="body2"
                  color="text"
                  opacity={0.8}
                  fontWeight="regular"
                  height="100%"
                >
                  Accept credit cards and ACH eChecks with the freedom of choosing your own Payment
                  Processor.
                </MKTypography>
                <MKBox mx="auto" width="fit-content" my={2}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={DiscoveryCard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>
                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={VisaCard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>
                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={Mastercard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>
                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={AmericanExpressCard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>

                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={PaypalCard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>
                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={ECheckCard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>
                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={IPayCard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>
                    <Grid item md={3} sm={2}>
                      <MKBox
                        component="img"
                        src={JCBCard}
                        alt="Brand"
                        sx={{ width: { md: "9rem", xs: "5rem" } }}
                      />
                    </Grid>
                  </Grid>
                </MKBox>
              </Grid>
            </MKBox>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Authorize;
