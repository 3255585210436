/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultNavbarDropdown({ label, icon, href }) {
  return (
    <>
      <MKBox
        component="a"
        href={`#${href}`}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>{icon}</Icon>
        <MKTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
        >
          {label}
        </MKTypography>
      </MKBox>
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  href: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  href: PropTypes.string,
};

export default DefaultNavbarDropdown;
