// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import MKTypography from "components/MKTypography";
import { Icon } from "@mui/material";
import Stack from "@mui/material/Stack";
import AboutUsOption from "./AboutUsOption";

const AboutUs = () => (
  <MKBox component="section" py={18} id="features">
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={12} lg={5}>
          <MKTypography variant="h3" my={1}>
            One Stop Shop MSP Billing Platform
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={2}>
            If month end is a nightmare or payment processors are gouging you, you’ve come to the
            right page. Our billing and payment solutions are geared towards the modern MSP with a
            slick and secure portal experience for you and your customers. Integrate data flow
            seamlessly between your practice management and accounting platform.
          </MKTypography>
          <MKTypography
            component="a"
            href="#"
            variant="body2"
            color="info"
            fontWeight="regular"
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: "translateX(3px)",
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: "translateX(6px)",
              },
            }}
          >
            More about us
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          <Stack>
            <AboutUsOption
              icon="mediation"
              content={
                <>
                  We sync your invoice data from PSA to accounting platform, send invoices on your
                  behalf, and remind your clients of due or overdue invoices. Stop wasting hours
                  every month making those awkward demand phone calls.
                </>
              }
            />
            <AboutUsOption
              icon="settings_overscan"
              content={
                <>
                  When they are ready to pay, we help you accept multiple forms of payments. Your
                  clients can track invoice status and store payment methods in a secured vault
                  within the customer portal, enabling autopay.
                </>
              }
            />
            <AboutUsOption
              icon="token"
              content={
                <>
                  After payment, we sync payment information to your PSA and accounting platform.
                  Customers use the portal to track invoices and payment history.
                </>
              }
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </MKBox>
);

export default AboutUs;
