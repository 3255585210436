/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import * as Sentry from "@sentry/react";

// react-router components
import { Routes, Route, Link, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 PRO React routes
import routes from "routes";

// pages
import Privacy from "layouts/pages/support/privacy";
import TermsofUse from "layouts/pages/support/termsofuse";
import ContactUs from "layouts/pages/support/contact-us";
import AboutUsPage from "layouts/pages/company/about-us";
import ProductsPage from "layouts/products";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SentryRoutes>
        {getRoutes(routes)}

        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-of-use" element={<TermsofUse />} />

        <Route exact path="/" element={<Presentation />} />
        <Route path="*" element={<Link to="/" />} />
      </SentryRoutes>
    </ThemeProvider>
  );
}
export default Sentry.withProfiler(App);
