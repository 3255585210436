// core component
import Grid from "@mui/material/Grid";

// components
import MKBox from "components/MKBox";
import NavBar from "components/Navbar";
import CenteredFooter from "components/Footer/CenteredFooter";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

function ContactUs() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <NavBar routes={routes} />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <MKBox mt={20} mx="auto">
          Product page
        </MKBox>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
